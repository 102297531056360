<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="350">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create Access Permission
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form.type.selected"
                        :items="form.type.items"
                        :rules="form.type.rule"
                        item-text="name"
                        item-value="id"
                        label="Profile Type"
                        outlined
                    ></v-autocomplete>
                    <v-autocomplete
                        dense
                        v-model="form.role.selected"
                        :items="form.role.items"
                        :rules="form.role.rule"
                        item-text="name"
                        item-value="id"
                        label="Role"
                        outlined
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'allInfo', 'profilerole', 'profiletype'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                type: {items: [], selected: '', rule: []},
                role: {items: [], selected: '', rule: []},
            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async getAll(){
            this.form.type.items.push({name: 'Select', id: ''});
            for (const [index, item] of this.profiletype.entries()){
                this.form.type.items.push({name: `${item.ID} - ${item.TypeName}`, id: item.ID});
            }

            this.form.role.items.push({name: 'Select', id: ''});
            for (const [index, item] of this.profilerole.entries()){
                this.form.role.items.push({name: `${item.ID} - ${item.RoleName}`, id: item.ID});
            }
        },

        async checkRef(){
            let system = await this.allInfo;
            let check = system.some((item) => {
                return item.TypeID == Number(this.form.type.selected) && item.RoleID == Number(this.form.role.selected);
            });

            return check;
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
        },

        async beforeCreateForm(flag){
            let checkref = await this.checkRef();

            this.form.type.rule = [
                v => !!v || 'Type is required',
            ];

            this.form.role.rule = [
                v => !!v || 'Role is required',
                v => !checkref || 'Please choose other combinations. This combination has already been added'
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.getAll();
            this.form.type.rule = [];
            this.form.role.rule = [];
            this.form.type.selected = '';
            this.form.role.selected = '';
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>